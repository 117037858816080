import React from 'react'
import { TableCell } from '@papertrail/styleguide'

interface Params {
  record: Record
}

interface Record {
  account: Data
  folder: Data
  name: string
}

interface Data {
  data: {
    name: string
  }
}

const RecordName = (params: Params) => {
  const { record } = params

  return (
    <TableCell style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ paddingBottom: '20px', color: '#a6afbb' }}>
        {record.account.data.name} {' > '} {record.folder.data.name}
      </div>
      <div>{record.name}</div>
    </TableCell>
  )
}

export default RecordName
