import React from 'react'
import { ArrowBackIcon, Box, Chip } from '@papertrail/styleguide'
import { useHistory } from 'react-router-dom'

interface Params {
  searchString: string
}

const SubHeader = (params: Params) => {
  const { searchString } = params
  const history = useHistory()
  const chipLabel = 'Search: ' + searchString

  const redirect = () => {
    history.push(`/accounts`)
    const universalSearch = new CustomEvent('universalSearch', {
      detail: {
        show: true
      }
    })
    window.dispatchEvent(universalSearch)
  }

  return (
    <Box
      px={2}
      height={'54px'}
      flexShrink={0}
      sx={{ backgroundColor: '#007aff', color: 'white' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'left'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        style={{ cursor: 'pointer', marginRight: '24px' }}
        onClick={redirect}>
        <ArrowBackIcon style={{ paddingRight: '2px' }} /> Back
      </Box>
      {searchString && (
        <Box display={'flex'} alignItems={'center'} sx={{ backgroundColor: '#007aff', color: 'white' }}>
          <Chip label={chipLabel} variant="outlined" />
        </Box>
      )}
    </Box>
  )
}

export default SubHeader
