import React from 'react'
import { TableCell, Badge } from '@papertrail/styleguide'

interface Params {
  nextCheckDate: string
  isDue: boolean
  isOverdue: boolean
}

const NextCheck = (params: Params) => {
  const { nextCheckDate, isDue, isOverdue } = params

  const checkCountdown = (date) => {
    let timeAgo
    const today = new Date()
    const checkDate = new Date(date)
    timeAgo = Math.floor(
      (Date.UTC(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate()) -
        Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
        (1000 * 60 * 60 * 24)
    )
    if (timeAgo > 0) {
      //if check is after today
      if (timeAgo < 30) {
        if (timeAgo === 1) {
          timeAgo = timeAgo + ' day'
        } else {
          timeAgo = timeAgo + ' days'
        }
      }
      if (timeAgo > 30) {
        timeAgo = Math.floor(timeAgo / 30)
        if (timeAgo === 1) {
          timeAgo = timeAgo + ' month'
        }
        if (timeAgo > 1 && timeAgo < 12) {
          timeAgo = timeAgo + ' months'
        }
        if (timeAgo > 11) {
          timeAgo = Math.ceil(timeAgo / 12)
          if (timeAgo === 1) {
            timeAgo = timeAgo + ' year'
          } else {
            timeAgo = timeAgo + ' years'
          }
        }
      }
    } else {
      // if check is before today
      if (timeAgo > -30) {
        if (timeAgo === -1) {
          timeAgo = Math.abs(timeAgo) + ' day ago'
        }
        if (timeAgo < -1) {
          timeAgo = Math.abs(timeAgo) + ' days ago'
        }
      }
      if (timeAgo < -30) {
        timeAgo = Math.floor(timeAgo / 30)
        if (timeAgo === -1) {
          timeAgo = Math.abs(timeAgo) + ' month ago'
        }
        if (timeAgo < -1 && timeAgo > -12) {
          timeAgo = Math.abs(timeAgo) + ' months ago'
        }
        if (timeAgo < -11) {
          timeAgo = Math.ceil(timeAgo / 12)
          if (timeAgo === -1) {
            timeAgo = Math.abs(timeAgo) + ' year ago'
          } else {
            timeAgo = Math.abs(timeAgo) + ' years ago'
          }
        }
      }
    }
    return timeAgo
  }

  return (
    <TableCell>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ paddingBottom: '8px' }}>
          {!isDue && !isOverdue && (
            <Badge badgeContent={''} variant="dot" color="success" style={{ marginRight: '8px' }}></Badge>
          )}
          {isDue && <Badge badgeContent={''} variant="dot" color="warning" style={{ marginRight: '8px' }}></Badge>}
          {isOverdue && <Badge badgeContent={''} variant="dot" color="error" style={{ marginRight: '8px' }}></Badge>}

          {new Date(nextCheckDate).toLocaleDateString('en-uk', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </div>

        {checkCountdown(nextCheckDate)}
      </div>
    </TableCell>
  )
}

export default NextCheck
