import React from 'react'
import {
  TableCell,
  DoneIcon,
  DeleteIcon,
  HelpIcon,
  LockIcon,
  SearchIcon,
  FlexRow,
  Box,
  UserAvatar
} from '@papertrail/styleguide'
import { Log, State } from 'src/types'

interface Params {
  currentState: State
  user
  loggedAt: string
  log?: Log
}

const userMapper = (data) => {
  const user = {
    initials: data.first_name.charAt(0) + data.last_name.charAt(0).toUpperCase(),
    avatar: data.avatar,
    avatarColour: data.avatar_colour
  }
  return user
}

const RecordState = (params: Params) => {
  const { currentState, log, loggedAt } = params
  const user = userMapper(params.user.data)

  return (
    <TableCell style={{ textTransform: 'capitalize' }}>
      <FlexRow justifyContent="flex-start">
        <FlexRow alignItems="center" justifyContent={''}>
          {currentState.profile === 'good' && <DoneIcon sx={{ color: '#0B996A' }} />}
          {currentState.profile === 'monitor' && <SearchIcon color="warning" />}
          {currentState.profile === 'missing' && <HelpIcon color="error" />}
          {currentState.profile === 'retired' && <DeleteIcon color="warning" />}
          {currentState.profile === 'quarantine' && <LockIcon color="error" />}
          <FlexRow justifyContent={''} sx={{ paddingLeft: '8px', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Box style={{ paddingBottom: '8px', width: '130px' }}>{currentState.name}</Box>
            <Box>
              {log &&
                new Date(log.logged_at).toLocaleDateString('en-uk', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              {!log &&
                new Date(loggedAt).toLocaleDateString('en-uk', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
            </Box>
          </FlexRow>
        </FlexRow>
        <Box style={{ paddingLeft: '8px' }}>
          <UserAvatar avatarDetails={user} size="small" />
        </Box>
      </FlexRow>
    </TableCell>
  )
}

export default RecordState
